const getPageRange = (current, total) => {
        const delta = 2; // Number of pages to show on each side of current page and ellipses
        let range = [];

        if (total <= 7) {
            // If total pages are 7 or less, show all pages
            range = [...Array(total)].map((_, i) => i + 1);
        } else {
            if (current <= 4) {
                // Near the beginning
                range = [1, 2, 3, 4, 5, '...', total];
            } else if (current >= total - 3) {
                // Near the end
                range = [1, '...', total - 4, total - 3, total - 2, total - 1, total];
            } else {
                // Somewhere in the middle
                range = [1, '...', current - 1, current, current + 1, '...', total];
            }
        }

        return range;
    };

const Pagination = ({ table, rowsPerPage, pageCount }) => {
    const totalPages = Math.ceil(pageCount / rowsPerPage);
    const currentPage = table.getState().pagination.pageIndex + 1;
    const paginationArray = getPageRange(currentPage, totalPages);

    return (
        <div className="flex justify-between w-full mx-auto my-6">
            <button
                type="button"
                onClick={() => table.setPageIndex((prevValue) => prevValue - 1)}
                disabled={!table.getCanPreviousPage()}
                className={`transition flex items-center text-black border-0 hover:text-white bg-transparent disabled:bg-transparent disabled:cursor-not-allowed ${
                    table.getCanPreviousPage()
                        ? "hover:bg-transparent hover:text-gray-500"
                        : "hover:text-gray-500"
                }`}
            >
                <svg width={14} height={8} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.1665 4H12.8332"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M1.1665 4L4.49984 7.33333"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M1.1665 4.00002L4.49984 0.666687"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <p className="ml-3 text-sm font-medium leading-none ">Previous</p>
            </button>
            <div className="flex">
                {paginationArray.map((item, index) => {
                    if (item === '...') {
                        return (
                            <span key={`ellipsis-${index}`} className="px-2 py-2 text-gray-600">
                                ...
                            </span>
                        );
                    }
                    return (
                        <button
                            className={`hover:bg-transparent transition border-[1px] border-gray-200 border-x-transparent hidden sm:inline bg-transparent rounded-none text-sm font-medium leading-none text-gray-600 
                             py-2 mr-4 px-2 hover:border-t-ihs-green-shade-400 hover:border-b-ihs-green-shade-400 hover:text-ihs-green-shade-700 disabled:hover:text-ihs-green-shade-700 disabled:text-ihs-green-shade-700 disabled:cursor-not-allowed disabled:border-y-ihs-green-shade-400 disabled:bg-transparent 
                            `}
                            key={item}
                            onClick={() => table.setPageIndex(item - 1)}
                            disabled={currentPage === item}
                        >
                            {item}
                        </button>
                    );
                })}

                <button
                    type="button"
                    onClick={() => table.setPageIndex((prevValue) => prevValue + 1)}
                    disabled={!table.getCanNextPage()}
                    className={`transition flex items-center text-black border-0 hover:text-white bg-transparent disabled:bg-transparent disabled:cursor-not-allowed ${
                        table.getCanNextPage()
                            ? "hover:text-gray-500 hover:bg-transparent"
                            : "hover:text-gray-600"
                    }`}
                >
                    <p className="mr-3 text-sm font-medium leading-none">Next</p>
                    <svg
                        width={14}
                        height={8}
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.1665 4H12.8332"
                            stroke="currentColor"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M9.5 7.33333L12.8333 4"
                            stroke="currentColor"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M9.5 0.666687L12.8333 4.00002"
                            stroke="currentColor"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Pagination;
