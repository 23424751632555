import { Link } from 'react-router-dom';
import { ChevronRightIcon } from "@heroicons/react/solid";

const Breadcrumb = ({ links }) => {
  return (
    <nav aria-label="">
      <ol className="flex items-center my-4 space-x-2 text-sm text-slate-500 lg:mt-10 lg:mb-8">
        {links.map((link, index) => (
            <li key={index} className={`m-0 p-0 ${index === links.length - 1 ? 'text-black' : ''}`}>
            {index === links.length - 1 ? (
              link.label
              ) : (
              <div className='flex space-x-2'>
                <Link to={link.path}>{link.label}</Link>
                <ChevronRightIcon className="w-4" />
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
export default Breadcrumb