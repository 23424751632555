import { UserCircleIcon } from "@heroicons/react/outline";

const PageHeading = ({
  pageName,
  icon: Icon,
  children,
}) => {
  return (
    <>
      <div className="flex items-center justify-between text-gray-600 rounded-md shadow-sm bg-ihs-green-shade-50 md:pr-4">
        <div className="flex items-center px-4 py-4 space-x-2 md:px-8 md:space-x-4">
          {Icon ? <Icon className="w-8" /> : <UserCircleIcon className="w-8" />}
          <h3 className="text-base font-semibold md:text-xl">{pageName}</h3>
        </div>
        {children}
      </div>
    </>
  );
};
export default PageHeading;
